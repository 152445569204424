.footer {
  background: #7bb4e3;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #0d214f;
  left: 0;
   /* margin-top: auto; */
}
.footer p {
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .footer p {
    font-size: 0.75rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    opacity: 0;
  }
}
