@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.onlyBody {
  height: 100%;
}

body {
  padding-top: 4rem;
  margin: 0px;
  color: #333;
  /* --type-first: Arial, Helvetica, sans-serif;
  --type-second: 'Spectral', Georgia; */
  --type-first: "Nunito", Helvetica, sans-serif;
  --type-second: "Spectral", Georgia;
  font-family: var(--type-first);
  background-color: #ffffff;
  /* position: relative; */
  display: block;
  width: 100%;
  height: 100%;

  padding-bottom: 1.5rem;
}

@media screen and (max-width: 1200px) {
  body {
    padding-top: 3.3rem;
    margin: 0px;
    color: #333;
    --type-first: "Nunito", Helvetica, sans-serif;
    --type-second: "Nunito", Georgia;
    font-family: var(--type-first);
    background-color: #ffffff;
    /* position: relative; */
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 1.1rem;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.containerLogin {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start; 
  gap: 20px; 
}

.leftColumn, .rightColumn {
  flex: 1; 
}

.leftColumn {
  max-width: 50%; 
}

.rightColumn {
  max-width: 50%; 
}


.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  /* background: #084d6e; */
  position: absolute;
  bottom: 5px;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}
.button-menu {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #084d6e;
  color: #0d214f;
  /* min-width: 8rem; */
  /* padding: 0.8rem 1.2rem; */
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
}
.button-menu:hover,
.button-menu:focus {
  outline: none;
  box-shadow: 0 0 0 3px white, 0 0 0 4px white;
}
.container-home {
  margin-top: 30px;
}
.user-name {
  font-size: 0.8rem;
  /* font-weight: bold; */
  color: #0d214f;
  /* margin-right: 10px; */
}

.user-name:hover {
  color: white;
  cursor: pointer;
  outline: none;
}
.load {
  height: 8rem;
  margin-top: 10rem;
  text-align: center;
}
.img-google {
  margin-right: 1rem;
}

input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  
}
input[type=number] { 
 -moz-appearance: textfield;
 appearance: textfield;

}

.estilizada {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.estilizada2 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada2 th, .estilizada2 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada2 th {
  background-color: #f2f2f2;
  color: #0d214f;
}

