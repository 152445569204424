.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #4e97d1;
  top: 0px;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* height: 4rem; */
}

@media screen and (max-width: 500px) {
  .nav {
    font-size: 13px;
    margin-right: 4%;
    justify-content: space-between;
  }
}

.iconVet {
  height: 50px;
  transition: 2s;
}

@media screen and (max-width: 600px) {
  .iconVet {
    height: 40px;
  }
}

@media screen and (max-width: 400px) {
  .iconVet {
    margin-right: 4%;
  }
}

.logo {
  padding: 0.5rem 0;
}

.login {
  /* color:#0073cf; */
  display: flex;
  align-items: center;
  font-weight: bold;
}

.login:hover {
  content: "";
  color: #fafafa;
  border-bottom: 3px solid #fafafa;
  transition: all 0.3s ease;
}

.containerFlex {
  margin-top: 10px;
}
