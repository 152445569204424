.estilizada {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.estilizada2 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada2 th, .estilizada2 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada2 th {
  background-color: #f2f2f2;
  color: #0d214f;
}