@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.onlyBody {
  height: 100%;
}

body {
  padding-top: 4rem;
  margin: 0px;
  color: #333;
  /* --type-first: Arial, Helvetica, sans-serif;
  --type-second: 'Spectral', Georgia; */
  --type-first: "Nunito", Helvetica, sans-serif;
  --type-second: "Spectral", Georgia;
  font-family: var(--type-first);
  background-color: #ffffff;
  /* position: relative; */
  display: block;
  width: 100%;
  height: 100%;

  padding-bottom: 1.5rem;
}

@media screen and (max-width: 1200px) {
  body {
    padding-top: 3.3rem;
    margin: 0px;
    color: #333;
    --type-first: "Nunito", Helvetica, sans-serif;
    --type-second: "Nunito", Georgia;
    font-family: var(--type-first);
    background-color: #ffffff;
    /* position: relative; */
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 1.1rem;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.containerLogin {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start; 
  grid-gap: 20px; 
  gap: 20px; 
}

.leftColumn, .rightColumn {
  flex: 1 1; 
}

.leftColumn {
  max-width: 50%; 
}

.rightColumn {
  max-width: 50%; 
}


.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  /* background: #084d6e; */
  position: absolute;
  bottom: 5px;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
}

@-webkit-keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}
.button-menu {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #084d6e;
  color: #0d214f;
  /* min-width: 8rem; */
  /* padding: 0.8rem 1.2rem; */
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
}
.button-menu:hover,
.button-menu:focus {
  outline: none;
  box-shadow: 0 0 0 3px white, 0 0 0 4px white;
}
.container-home {
  margin-top: 30px;
}
.user-name {
  font-size: 0.8rem;
  /* font-weight: bold; */
  color: #0d214f;
  /* margin-right: 10px; */
}

.user-name:hover {
  color: white;
  cursor: pointer;
  outline: none;
}
.load {
  height: 8rem;
  margin-top: 10rem;
  text-align: center;
}
.img-google {
  margin-right: 1rem;
}

input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  
}
input[type=number] {
 -webkit-appearance: textfield;
         appearance: textfield;

}

.estilizada {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.estilizada2 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada2 th, .estilizada2 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada2 th {
  background-color: #f2f2f2;
  color: #0d214f;
}


.Login_login__2l_xz {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 102.75%;
  grid-gap: 2rem;
  gap: 2rem;
}

.Login_login__2l_xz::before {
  display: block;
  content: '';
  /* background: url('../icons/login.jpg') no-repeat center center; */
  background-size: cover;
}

.Login_forms__3lG9- {
  max-width: 30rem;
  padding: 1rem;
}

@media (max-width: 40rem) {
  .Login_login__2l_xz {
    grid-template-columns: 1fr;
  }
  .Login_login__2l_xz::before {
    display: none;
  }
  .Login_forms__3lG9- {
    max-width: 100%;
  }
}

body {
  padding-bottom: 100px;
}

.GlobalCss_container__k5jek {
  background: #ffffff;
}

.GlobalCss_defaultGrid__q4seu {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  bottom: 24px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 0.8fr repeat(6, 1fr) 0.8fr;
  grid-template-rows: -webkit-max-content -webkit-max-content 1fr;
  grid-template-rows: max-content max-content 1fr;
} /*Responsável pelo Grid de Quase Todas as Páginas*/

.GlobalCss_defaultGrid__q4seu svg {
  vertical-align: middle !important;
  max-width: 100%;
} /*Responsável pela Padronização dos SVGs*/

.GlobalCss_defaultGrid__q4seu form {
  display: grid;
  transition: 0.2s;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 3/7;
} /*Formulário Padrão do Site*/

.GlobalCss_lottie__1VCbY svg {
  height: 150px !important;
  width: 325px !important;
} /*Estilo de Lottie Padrão do Elemento HTML SVG*/

.GlobalCss_lottie__1VCbY {
  grid-column: 3/7 !important;
} /*Estilo de Lottie Padrão*/

.GlobalCss_lottie2__2MQBy {
  grid-column: 3/7 !important;
} /*Variação do Estilo de Lottie Padrão*/

.GlobalCss_lottie2__2MQBy svg {
  height: 45px !important;
  width: 200px !important;
} /*Variação do Estilo de Lottie Padrão do ELemento HTML SVG*/

.GlobalCss_noIconStyle__UJTje {
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #084d6e;
  color: #0d214f;
  margin-left: 1.5%;
  padding: 0rem 1.5rem;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
} /*Variação com pouca estilização dos Estilos de Icone*/

.GlobalCss_noIconStyle__UJTje:hover,
.GlobalCss_noIconStyle__UJTje:focus {
  outline: none;
  box-shadow: 0 0 0 3px #c3de9b, 0 0 0 4px #91b162;
} /*Variação com pouca estilização dos Estilos de Icone das ações hover e focus*/

.GlobalCss_noIconStyle__UJTje:disabled {
  outline: none;
  box-shadow: none;
  cursor: wait;
  opacity: 0.5;
} /*Variação com pouca estilização dos Estilos de Icone quando o Elemento estiver desabilitado*/


.GlobalCss_sectionStyle__3JlJD section {
  position: unset !important;

  top: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
  transition: 1s opacity;
}

.GlobalCss_container__k5jek {
  background-color: #bbb;
  text-align: center;
  border-radius: 10px;
}
.GlobalCss_container__k5jek li {
  margin: 5px;
  padding: 1rem;
  color: #0d214f;
  font-weight: bold;
  font-family: 'Nunito', Helvetica;
}

.GlobalCss_container2__ahmt9 {
  background: #ffffff;
}
.GlobalCss_gridContainer2__2RGn0 {
  grid-column: 2/8 !important;
  margin-bottom: 0.5%;
}
.GlobalCss_container2__ahmt9 {
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 0.1rem;
}

.GlobalCss_container2__ahmt9 li {
  color: #0d214f;
  font-weight: bold;
  font-family: 'Nunito', Helvetica;
}

.GlobalCss_container__k5jek p {
  font-size: 85%;
  color: #0d214f;
  font-weight: bold;
  font-family: 'Nunito', Helvetica;
}

.GlobalCss_containerGrid__wRVBt {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 3/7;
  text-align: center;
}

.GlobalCss_containerError__2d4UF {
  margin-top: 1rem;
}

.GlobalCss_title__1X9Vd {
  margin: 10px auto 15px;
  grid-column: 3/7;
}

.GlobalCss_pSpace__2qWV6 {
  margin-bottom: 7px;
}

.GlobalCss_title2__1NXG8 {
  margin: 15px auto 20px;
  grid-column: 2/8;
}

.GlobalCss_title3__1oHoB {
  margin: 15px auto 20px;
  grid-column: 4/6;
}

.GlobalCss_inputStyleTutors__TVjP3 {
  grid-column: 1/5 !important;
  justify-self: center;
  width: 70%;
}
.GlobalCss_inputStyleAddTutors__32FDo {
  grid-column: 1/5 !important;
  display: flex;
  justify-content: space-between;
}

.GlobalCss_inputAddTutors__1QzaA {
  width: 49%;
  margin-top: 1.5%;
}

.GlobalCss_title__1X9Vd h1,
.GlobalCss_title2__1NXG8 h1,
.GlobalCss_title3__1oHoB h1 {
  text-align: center;
  font-size: 3.4vw;
  font-weight: 600;
  letter-spacing: 2.5px;
  color: #0d214f;
  transition: 0.8s !important;
}

.GlobalCss_title__1X9Vd h2,
.GlobalCss_title2__1NXG8 h2,
.GlobalCss_title3__1oHoB h2 {
  text-align: center;
  font-size: 2.8vw;
  font-weight: 600;

  color: #0d214f;
  transition: 0.8s !important;
}

.GlobalCss_title__1X9Vd h3,
.GlobalCss_title2__1NXG8 h3,
.GlobalCss_title3__1oHoB h3 {
  text-align: center;
  font-size: 2.2vw;
  color: #0d214f;
  transition: 0.8s !important;
}

.GlobalCss_title__1X9Vd h4,
.GlobalCss_title2__1NXG8 h4,
.GlobalCss_title3__1oHoB h4 {
  text-align: center;
  font-size: 1.6vw;
  color: #0d214f;
  transition: 0.8s !important;
}

.GlobalCss_perdeu__3z2t8 {
  display: inline-block;
  color: #666;
  padding: 0.5rem 0;
  line-height: 1;
}

.GlobalCss_perdeu__3z2t8::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.GlobalCss_cadastro__1dWOh {
  margin-top: 4rem;
}

.GlobalCss_cadastro__1dWOh p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.GlobalCss_subtitle__3gosL {
  font-family: var(--type-first);
  line-height: 1;
  font-size: 2rem;
}

.GlobalCss_subtitle__3gosL::after {
  content: '';
  display: block;
  background: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
}

@media print {
  .GlobalCss_button1__fNyT2 {
    display: none;
  }
}

.GlobalCss_button1__fNyT2 {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #7bb4e3;
  color: #0d214f;
  /* max-width: 100%;
  max-height: 3rem; */
  padding: 0.6rem 1.5rem;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-left: 100px; */
}

.GlobalCss_button1__fNyT2:hover,
.GlobalCss_button1__fNyT2:focus {
  outline: none;
  box-shadow: 0 0 0 3px #0d214f, 0 0 0 4px #0d214f;
}

.GlobalCss_button1__fNyT2:disabled {
  opacity: 0.5;
  cursor: default;
  outline: none;
  box-shadow: none;
}

.GlobalCss_button2__a1sye {
  font-size: 1rem;
  transition: 0.2s;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #0073cf;
  color: #0d214f;
  min-width: 6rem;
  height: 3rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
  margin-top: 4px;
  margin-left: 22px;
}

.GlobalCss_button3__2UGgN {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #0073cf;
  color: #0d214f;
  /* max-width: 100%;
  max-height: 3rem; */
  padding: 1.5% 10%;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-left: 100px; */
}

.GlobalCss_button3__2UGgN:hover,
.GlobalCss_button3__2UGgN:focus {
  outline: none;
  box-shadow: 0 0 0 3px #0d214f
  , 0 0 0 4px #0d214f
  ;
}

.GlobalCss_button3__2UGgN:disabled {
  opacity: 0.5;
  cursor: wait;
}

.GlobalCss_buttonGoogle__3M3kt {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #7bb4e3;
  color: #0d214f;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
}

.GlobalCss_buttonGoogle__3M3kt:hover,
.GlobalCss_buttonGoogle__3M3kt:focus {
  outline: none;
  box-shadow: 0 0 0 3px #0d214f, 0 0 0 4px #0d214f;
}

.GlobalCss_buttonGoogle__3M3kt:disabled {
  opacity: 0.5;
  cursor: wait;
}

.GlobalCss_buttonBack__KCP-f {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: none;
  color: #0d214f;
  max-width: 50%;
  max-height: 3rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.GlobalCss_videoStyle__27VRa video {
  top: 0px;
  left: 0px;
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.GlobalCss_personalizado1__Btkz9 {
  grid-column: 3/4;
  margin-left: 2%;
}
.GlobalCss_personalizado2__2UWm6 {
  grid-column: 3/4;
  margin-left: 10%;
}

.GlobalCss_flexButton__3Asyc {
  display: flex;
  justify-content: space-around;
  transition: 1s;
}

.GlobalCss_link__1SHQR {
  color: #0d214f;
  width: -webkit-max-content;
  width: max-content;
  display: block;
}
.GlobalCss_link__1SHQR span {
  font-weight: bold;
  text-align: center;
  display: unset;
  align-items: center;
  justify-content: center;
}

.GlobalCss_link__1SHQR p {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}



.GlobalCss_link__1SHQR:hover {
  color: #7bb4e3;
}

.GlobalCss_icon__94LVR {
  width: 80px;
}
.GlobalCss_niceDatesForm__1wsGw {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1/5;
}

.GlobalCss_iconStyle__3zBzH {
  font-size: 22.5px;
}

.GlobalCss_inputNiceDates__22FSf {
  grid-column: span 2;
}
.GlobalCss_ps1__ygauB {
  grid-column: 1/5;
  display: flex;
  justify-content: space-between;
  justify-self: center;
  width: 70%;
}
.GlobalCss_toggleHiden__2fnBm {
  display: none;
}

.GlobalCss_styleInputChart__2xIjh {
  background-color: #ffffff !important;
  width: 20% !important;
  position: absolute;
  width: 200px;
  background: red;
  top: 0;
  right: 0;
}

.GlobalCss_textIcon__2D76u {
  font-size: 95% !important;
}

.GlobalCss_lottie__1VCbY svg {
  align-self: center !important;
  width: unset !important;
  height: unset !important;
}

.GlobalCss_iconStyle2__2rlnU {
  font-size: 5rem;
  width: 100% !important;
}
.GlobalCss_iconStyle3__rA-Ga {
  font-size: 4rem;
}


@media screen and (max-width: 700px) {
  .GlobalCss_iconStyle3__rA-Ga {
    align-items: center;
    justify-content: center;
    margin-left: 35%;
    /* margin-right: auto; */
  }
}

@media screen and (max-width: 650px) {
  .GlobalCss_iconStyle3__rA-Ga { 
    margin-left: 40%;
  }
}

@media screen and (max-width: 600px) {
  .GlobalCss_iconStyle3__rA-Ga { 
    margin-left: 30%;
  }
}

@media screen and (max-width: 450px) {
  .GlobalCss_iconStyle3__rA-Ga { 
    margin-left: 40%;
  }
}

@media screen and (max-width: 450px) {
  .GlobalCss_iconStyle3__rA-Ga { 
    margin-left: 53%;
  }
}

@media screen and (max-width: 350px) {
  .GlobalCss_iconStyle3__rA-Ga { 
    margin-left: 35%;
  }
}

@media screen and (max-width: 350px) {
  .GlobalCss_iconStyle3__rA-Ga { 
    margin-left: 40%;
  }
}

.GlobalCss_iconStyle4__3_ALn {
  font-size: 2.2vw;
}

.GlobalCss_iconStyle5__3wPHh {
  font-size: 1.5rem;
}
.GlobalCss_modEspTitle__1v1Ji {
  grid-column: 4/6 !important;
  transition: 2s;
}
.GlobalCss_botaoPersona__2wida {
  padding: 0.6rem 1.2rem;
  font-size: 14px !important;
  transition: 2s width;
  margin-top: 9%;
}

.GlobalCss_botaoPersona2__3mlxC {
  grid-column: 6/7 !important;
}

.GlobalCss_button4__1hns5 {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #7bb4e3;
  color: #0d214f;

  padding: 0.4rem 0.8rem;
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
  margin-top: 10px;
}


@media screen and (max-width: 600px) {
  .GlobalCss_lottie__1VCbY {
    grid-column: 1/9 !important;
  }
  .GlobalCss_lottie2__2MQBy {
    grid-column: 1/9 !important;
  }
  .GlobalCss_botaoPersona2__3mlxC {
    grid-column: 7/8 !important;
  }

  .GlobalCss_botaoPersona__2wida {
    margin-top: 17.5% !important;
  }

  .GlobalCss_link__1SHQR p {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .GlobalCss_ps1__ygauB {
    grid-column: 2/6;
    display: flex;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
  }
  .GlobalCss_flexButton__3Asyc {
    display: flex;
    justify-content: space-between;
  }
  .GlobalCss_textIcon__2D76u {
    font-size: 75% !important;
  }
  .GlobalCss_iconStyle__3zBzH {
    font-size: 15px;
    height: 450%;
    margin-top: -17px;
  }
  .GlobalCss_iconStyle2__2rlnU {
    font-size: 4rem;
  }
  .GlobalCss_iconStyle3__rA-Ga {
    font-size: 3rem;
  }

  .GlobalCss_button1__fNyT2 {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;

    padding: 0.4rem 0.8rem;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }

  @media screen and (max-width: 550px) {
    .GlobalCss_button4__1hns5 {    
      width: 20% !important;
      margin-top: 22.5px;
      margin-left: 10%; 
      padding: 10px;
    }
  } 

 @media screen and (max-width: 500px) {
    .GlobalCss_button4__1hns5 {    
      width: 25% !important;
      margin-top: 22.5px;
      margin-left: 10%; 
    }
  }

  .GlobalCss_button2__a1sye {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;
    min-width: 5rem;
    height: 2.5rem;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }
  .GlobalCss_button3__2UGgN {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;

    padding: 1% 10%;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }

  .GlobalCss_button4__1hns5 {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;

    padding: 0.4rem 0.8rem;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }



  .GlobalCss_personalizado1__Btkz9 {
    grid-column: 4/5;
  }
  .GlobalCss_personalizado2__2UWm6 {
    grid-column: 4/6;
    margin-left: 10%;
  }

  .GlobalCss_icon__94LVR {
    width: 65px;
  }
  .GlobalCss_containerGrid__wRVBt {
    grid-column: 2/8;
    text-align: center;
  }

  /* @media screen and (max-width: 700px) {
    .containerGrid {
      grid-column: 2/6;
      display: block;
      background: #c3c3c3;
    }
  } */


  .GlobalCss_container__k5jek p {
    font-size: 75%;
  }
  .GlobalCss_niceDatesForm__1wsGw {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .GlobalCss_inputNiceDates__22FSf {
    grid-column: span 3;
  }

  .GlobalCss_gridContainer2__2RGn0 {
    grid-column: 1/9 !important;
  }
  .GlobalCss_defaultGrid__q4seu {
    bottom: 18px;
  }

  .GlobalCss_title__1X9Vd {
    grid-column: 2/8;
  }

  .GlobalCss_title2__1NXG8 {
    grid-column: 1/9;
  }

  .GlobalCss_title__1X9Vd h1,
  .GlobalCss_title2__1NXG8 h1,
  .GlobalCss_title3__1oHoB h1 {
    font-size: 5.4vw !important;
    font-weight: 600;
    color: #0d214f;
  }

  .GlobalCss_title__1X9Vd h2,
  .GlobalCss_title2__1NXG8 h2,
  .GlobalCss_title3__1oHoB h2 {
    font-size: 5vw !important;
    font-weight: 600;
  }

  .GlobalCss_title__1X9Vd h3,
  .GlobalCss_title2__1NXG8 h3,
  .GlobalCss_title3__1oHoB h3 {
    font-size: 4.2vw !important;
  }

  .GlobalCss_iconStyle4__3_ALn {
    font-size: 4.2vw !important;
  }

  .GlobalCss_title__1X9Vd h4,
  .GlobalCss_title2__1NXG8 h4,
  .GlobalCss_title3__1oHoB h4 {
    font-size: 3.4vw !important;
  }

  .GlobalCss_defaultGrid__q4seu form {
    display: grid;
    transition: 0.2s;
    grid-template-columns: repeat(6, 1fr);
    grid-column: 2/8;
    grid-template-rows: 90px 90px;
  }

  .GlobalCss_inputStyleTutors__TVjP3 {
    grid-column: 2/6 !important;
    justify-self: unset;
    width: unset;
  }
}

@media screen and (max-width: 800px) {
  .GlobalCss_botaoPersona__2wida {
    padding: 0.3rem 0.6rem !important;
    margin: unset;

    margin-left: unset !important;
  }
}

@media screen and (max-width: 1000px) {
  .GlobalCss_title__1X9Vd h4,
  .GlobalCss_title2__1NXG8 h4,
  .GlobalCss_title3__1oHoB h4 {
    text-align: center;
    font-size: calc(8px + 2vw);
    color: #0d214f;
  }
  .GlobalCss_inputStyleAddTutors__32FDo {
    grid-column: 1/7 !important;
    display: block;
  }
  .GlobalCss_inputAddTutors__1QzaA {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .GlobalCss_defaultGrid__q4seu {
    top: 52px;
  }

  .GlobalCss_title__1X9Vd h1,
  .GlobalCss_title2__1NXG8 h1,
  .GlobalCss_title3__1oHoB h1 {
    font-size: 4.6vw;
    color: #0d214f;
  }

  .GlobalCss_title__1X9Vd h2,
  .GlobalCss_title2__1NXG8 h2,
  .GlobalCss_title3__1oHoB h2 {
    font-size: 3.8vw;
  }

  .GlobalCss_title__1X9Vd h3,
  .GlobalCss_title2__1NXG8 h3,
  .GlobalCss_title3__1oHoB h3 {
    font-size: 3.4vw;
  }

  .GlobalCss_iconStyle4__3_ALn {
    font-size: 3.4vw;
  }
  .GlobalCss_botaoPersona__2wida {
    margin-top: 10%;
  }

  .GlobalCss_title__1X9Vd h4,
  .GlobalCss_title2__1NXG8 h4,
  .GlobalCss_title3__1oHoB h4 {
    font-size: 2.6vw;
  }
}

.GlobalCss_estilizada__1nVAH {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.GlobalCss_estilizada__1nVAH th, .GlobalCss_estilizada__1nVAH td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.GlobalCss_estilizada__1nVAH th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.GlobalCss_estilizada2__2kwi3 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.GlobalCss_estilizada2__2kwi3 th, .GlobalCss_estilizada2__2kwi3 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.GlobalCss_estilizada2__2kwi3 th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.Input_wrapper__1cnE6 {
  margin-bottom: 1rem;
}

.Input_input__SbV-4 {
  border: 1px solid #7bb4e3;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #7bb4e3;
  transition: 0.2s;
}

.Input_input__SbV-4:focus,
.Input_input__SbV-4:hover {
  outline: none;
  border-color: #0d214f;
  background: #7bb4e3;
  box-shadow: 0 0 0 3px #0d214f;
}

.Input_label__XpkTR {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__iQDwp {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.LoginForm_form__2FJQ9 {
  margin-bottom: 2rem;
}

.LoginForm_perdeu__3ll3B {
  display: inline-block;
  color: #666;
  padding: 0.5rem 0;
  line-height: 1;
}

.LoginForm_perdeu__3ll3B::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.LoginForm_cadastro__22PgZ {
  margin-top: 4rem;
}

.LoginForm_cadastro__22PgZ p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.LoginForm_subtitle__26dZi {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 2rem;
}

.LoginForm_subtitle__26dZi::after {
  content: '';
  display: block;
  background: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
  margin: 2px;
}
.LoginForm_containerError__1OUjP {
  margin-top: 1rem;
}

.Header_header__3-KQG {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #4e97d1;
  top: 0px;
}

.Header_nav__HgCo- {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* height: 4rem; */
}

@media screen and (max-width: 500px) {
  .Header_nav__HgCo- {
    font-size: 13px;
    margin-right: 4%;
    justify-content: space-between;
  }
}

.Header_iconVet__1EyK3 {
  height: 50px;
  transition: 2s;
}

@media screen and (max-width: 600px) {
  .Header_iconVet__1EyK3 {
    height: 40px;
  }
}

@media screen and (max-width: 400px) {
  .Header_iconVet__1EyK3 {
    margin-right: 4%;
  }
}

.Header_logo__2JaUt {
  padding: 0.5rem 0;
}

.Header_login__2U0XE {
  /* color:#0073cf; */
  display: flex;
  align-items: center;
  font-weight: bold;
}

.Header_login__2U0XE:hover {
  content: "";
  color: #fafafa;
  border-bottom: 3px solid #fafafa;
  transition: all 0.3s ease;
}

.Header_containerFlex__3rs1x {
  margin-top: 10px;
}

.HeaderUser_header__1j5Yp {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #7bb4e3;
  top: 0px;
}

.HeaderUser_nav__wY6Im {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .HeaderUser_header__1j5Yp {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 100;
    background: #7bb4e3;
    top: 0px;
  }

  .HeaderUser_nav__wY6Im {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .HeaderUser_nav__wY6Im p {
    font-size: 0.6rem;
  }
}

.Footer_footer__pze8s {
  background: #7bb4e3;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #0d214f;
  left: 0;
   /* margin-top: auto; */
}
.Footer_footer__pze8s p {
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .Footer_footer__pze8s p {
    font-size: 0.75rem;
    font-weight: bold;
  }
}

@media screen and (max-width: 600px) {
  .Footer_footer__pze8s {
    opacity: 0;
  }
}

.estilizada {
  border-collapse: collapse;
  width: 100%;
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
}

.icon {
  justify-content: center;
  color: #333;
  margin: auto 10px;
  /* margin-top: 10px; */
  display: inline-block;
  cursor: pointer;
}

.estilizada {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.estilizada2 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada2 th, .estilizada2 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada2 th {
  background-color: #f2f2f2;
  color: #0d214f;
}


.estilizada {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.estilizada2 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada2 th, .estilizada2 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada2 th {
  background-color: #f2f2f2;
  color: #0d214f;
}
@media print {
  .no-print {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
}
