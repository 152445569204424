.estilizada {
  border-collapse: collapse;
  width: 100%;
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
}

.icon {
  justify-content: center;
  color: #333;
  margin: auto 10px;
  /* margin-top: 10px; */
  display: inline-block;
  cursor: pointer;
}
