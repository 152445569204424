.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background: #7bb4e3;
  top: 0px;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 100;
    background: #7bb4e3;
    top: 0px;
  }

  .nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .nav p {
    font-size: 0.6rem;
  }
}
