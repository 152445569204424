body {
  padding-bottom: 100px;
}

.container {
  background: #ffffff;
}

.defaultGrid {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  bottom: 24px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 0.8fr repeat(6, 1fr) 0.8fr;
  grid-template-rows: max-content max-content 1fr;
} /*Responsável pelo Grid de Quase Todas as Páginas*/

.defaultGrid svg {
  vertical-align: middle !important;
  max-width: 100%;
} /*Responsável pela Padronização dos SVGs*/

.defaultGrid form {
  display: grid;
  transition: 0.2s;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 3/7;
} /*Formulário Padrão do Site*/

.lottie svg {
  height: 150px !important;
  width: 325px !important;
} /*Estilo de Lottie Padrão do Elemento HTML SVG*/

.lottie {
  grid-column: 3/7 !important;
} /*Estilo de Lottie Padrão*/

.lottie2 {
  grid-column: 3/7 !important;
} /*Variação do Estilo de Lottie Padrão*/

.lottie2 svg {
  height: 45px !important;
  width: 200px !important;
} /*Variação do Estilo de Lottie Padrão do ELemento HTML SVG*/

.noIconStyle {
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #084d6e;
  color: #0d214f;
  margin-left: 1.5%;
  padding: 0rem 1.5rem;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
} /*Variação com pouca estilização dos Estilos de Icone*/

.noIconStyle:hover,
.noIconStyle:focus {
  outline: none;
  box-shadow: 0 0 0 3px #c3de9b, 0 0 0 4px #91b162;
} /*Variação com pouca estilização dos Estilos de Icone das ações hover e focus*/

.noIconStyle:disabled {
  outline: none;
  box-shadow: none;
  cursor: wait;
  opacity: 0.5;
} /*Variação com pouca estilização dos Estilos de Icone quando o Elemento estiver desabilitado*/


.sectionStyle section {
  position: unset !important;

  top: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
  transition: 1s opacity;
}

.container {
  background-color: #bbb;
  text-align: center;
  border-radius: 10px;
}
.container li {
  margin: 5px;
  padding: 1rem;
  color: #0d214f;
  font-weight: bold;
  font-family: 'Nunito', Helvetica;
}

.container2 {
  background: #ffffff;
}
.gridContainer2 {
  grid-column: 2/8 !important;
  margin-bottom: 0.5%;
}
.container2 {
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 0.1rem;
}

.container2 li {
  color: #0d214f;
  font-weight: bold;
  font-family: 'Nunito', Helvetica;
}

.container p {
  font-size: 85%;
  color: #0d214f;
  font-weight: bold;
  font-family: 'Nunito', Helvetica;
}

.containerGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 3/7;
  text-align: center;
}

.containerError {
  margin-top: 1rem;
}

.title {
  margin: 10px auto 15px;
  grid-column: 3/7;
}

.pSpace {
  margin-bottom: 7px;
}

.title2 {
  margin: 15px auto 20px;
  grid-column: 2/8;
}

.title3 {
  margin: 15px auto 20px;
  grid-column: 4/6;
}

.inputStyleTutors {
  grid-column: 1/5 !important;
  justify-self: center;
  width: 70%;
}
.inputStyleAddTutors {
  grid-column: 1/5 !important;
  display: flex;
  justify-content: space-between;
}

.inputAddTutors {
  width: 49%;
  margin-top: 1.5%;
}

.title h1,
.title2 h1,
.title3 h1 {
  text-align: center;
  font-size: 3.4vw;
  font-weight: 600;
  letter-spacing: 2.5px;
  color: #0d214f;
  transition: 0.8s !important;
}

.title h2,
.title2 h2,
.title3 h2 {
  text-align: center;
  font-size: 2.8vw;
  font-weight: 600;

  color: #0d214f;
  transition: 0.8s !important;
}

.title h3,
.title2 h3,
.title3 h3 {
  text-align: center;
  font-size: 2.2vw;
  color: #0d214f;
  transition: 0.8s !important;
}

.title h4,
.title2 h4,
.title3 h4 {
  text-align: center;
  font-size: 1.6vw;
  color: #0d214f;
  transition: 0.8s !important;
}

.perdeu {
  display: inline-block;
  color: #666;
  padding: 0.5rem 0;
  line-height: 1;
}

.perdeu::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.cadastro {
  margin-top: 4rem;
}

.cadastro p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.subtitle {
  font-family: var(--type-first);
  line-height: 1;
  font-size: 2rem;
}

.subtitle::after {
  content: '';
  display: block;
  background: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
}

@media print {
  .button1 {
    display: none;
  }
}

.button1 {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #7bb4e3;
  color: #0d214f;
  /* max-width: 100%;
  max-height: 3rem; */
  padding: 0.6rem 1.5rem;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-left: 100px; */
}

.button1:hover,
.button1:focus {
  outline: none;
  box-shadow: 0 0 0 3px #0d214f, 0 0 0 4px #0d214f;
}

.button1:disabled {
  opacity: 0.5;
  cursor: default;
  outline: none;
  box-shadow: none;
}

.button2 {
  font-size: 1rem;
  transition: 0.2s;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #0073cf;
  color: #0d214f;
  min-width: 6rem;
  height: 3rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
  margin-top: 4px;
  margin-left: 22px;
}

.button3 {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #0073cf;
  color: #0d214f;
  /* max-width: 100%;
  max-height: 3rem; */
  padding: 1.5% 10%;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-left: 100px; */
}

.button3:hover,
.button3:focus {
  outline: none;
  box-shadow: 0 0 0 3px #0d214f
  , 0 0 0 4px #0d214f
  ;
}

.button3:disabled {
  opacity: 0.5;
  cursor: wait;
}

.buttonGoogle {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #7bb4e3;
  color: #0d214f;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
}

.buttonGoogle:hover,
.buttonGoogle:focus {
  outline: none;
  box-shadow: 0 0 0 3px #0d214f, 0 0 0 4px #0d214f;
}

.buttonGoogle:disabled {
  opacity: 0.5;
  cursor: wait;
}

.buttonBack {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: none;
  color: #0d214f;
  max-width: 50%;
  max-height: 3rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.2s;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.videoStyle video {
  top: 0px;
  left: 0px;
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.personalizado1 {
  grid-column: 3/4;
  margin-left: 2%;
}
.personalizado2 {
  grid-column: 3/4;
  margin-left: 10%;
}

.flexButton {
  display: flex;
  justify-content: space-around;
  transition: 1s;
}

.link {
  color: #0d214f;
  width: max-content;
  display: block;
}
.link span {
  font-weight: bold;
  text-align: center;
  display: unset;
  align-items: center;
  justify-content: center;
}

.link p {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}



.link:hover {
  color: #7bb4e3;
}

.icon {
  width: 80px;
}
.niceDatesForm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1/5;
}

.iconStyle {
  font-size: 22.5px;
}

.inputNiceDates {
  grid-column: span 2;
}
.ps1 {
  grid-column: 1/5;
  display: flex;
  justify-content: space-between;
  justify-self: center;
  width: 70%;
}
.toggleHiden {
  display: none;
}

.styleInputChart {
  background-color: #ffffff !important;
  width: 20% !important;
  position: absolute;
  width: 200px;
  background: red;
  top: 0;
  right: 0;
}

.textIcon {
  font-size: 95% !important;
}

.lottie svg {
  align-self: center !important;
  width: unset !important;
  height: unset !important;
}

.iconStyle2 {
  font-size: 5rem;
  width: 100% !important;
}
.iconStyle3 {
  font-size: 4rem;
}


@media screen and (max-width: 700px) {
  .iconStyle3 {
    align-items: center;
    justify-content: center;
    margin-left: 35%;
    /* margin-right: auto; */
  }
}

@media screen and (max-width: 650px) {
  .iconStyle3 { 
    margin-left: 40%;
  }
}

@media screen and (max-width: 600px) {
  .iconStyle3 { 
    margin-left: 30%;
  }
}

@media screen and (max-width: 450px) {
  .iconStyle3 { 
    margin-left: 40%;
  }
}

@media screen and (max-width: 450px) {
  .iconStyle3 { 
    margin-left: 53%;
  }
}

@media screen and (max-width: 350px) {
  .iconStyle3 { 
    margin-left: 35%;
  }
}

@media screen and (max-width: 350px) {
  .iconStyle3 { 
    margin-left: 40%;
  }
}

.iconStyle4 {
  font-size: 2.2vw;
}

.iconStyle5 {
  font-size: 1.5rem;
}
.modEspTitle {
  grid-column: 4/6 !important;
  transition: 2s;
}
.botaoPersona {
  padding: 0.6rem 1.2rem;
  font-size: 14px !important;
  transition: 2s width;
  margin-top: 9%;
}

.botaoPersona2 {
  grid-column: 6/7 !important;
}

.button4 {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #7bb4e3;
  color: #0d214f;

  padding: 0.4rem 0.8rem;
  box-sizing: border-box;
  transition: 0.1s;
  font-weight: bold;
  margin-top: 10px;
}


@media screen and (max-width: 600px) {
  .lottie {
    grid-column: 1/9 !important;
  }
  .lottie2 {
    grid-column: 1/9 !important;
  }
  .botaoPersona2 {
    grid-column: 7/8 !important;
  }

  .botaoPersona {
    margin-top: 17.5% !important;
  }

  .link p {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ps1 {
    grid-column: 2/6;
    display: flex;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
  }
  .flexButton {
    display: flex;
    justify-content: space-between;
  }
  .textIcon {
    font-size: 75% !important;
  }
  .iconStyle {
    font-size: 15px;
    height: 450%;
    margin-top: -17px;
  }
  .iconStyle2 {
    font-size: 4rem;
  }
  .iconStyle3 {
    font-size: 3rem;
  }

  .button1 {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;

    padding: 0.4rem 0.8rem;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }

  @media screen and (max-width: 550px) {
    .button4 {    
      width: 20% !important;
      margin-top: 22.5px;
      margin-left: 10%; 
      padding: 10px;
    }
  } 

 @media screen and (max-width: 500px) {
    .button4 {    
      width: 25% !important;
      margin-top: 22.5px;
      margin-left: 10%; 
    }
  }

  .button2 {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;
    min-width: 5rem;
    height: 2.5rem;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }
  .button3 {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;

    padding: 1% 10%;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }

  .button4 {
    font-size: 1rem;
    font-family: var(--type-first);
    cursor: pointer;
    border: none;
    border-radius: 0.4rem;
    background: #7bb4e3;
    color: #0d214f;

    padding: 0.4rem 0.8rem;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: bold;
    margin-top: 10px;
  }



  .personalizado1 {
    grid-column: 4/5;
  }
  .personalizado2 {
    grid-column: 4/6;
    margin-left: 10%;
  }

  .icon {
    width: 65px;
  }
  .containerGrid {
    grid-column: 2/8;
    text-align: center;
  }

  /* @media screen and (max-width: 700px) {
    .containerGrid {
      grid-column: 2/6;
      display: block;
      background: #c3c3c3;
    }
  } */


  .container p {
    font-size: 75%;
  }
  .niceDatesForm {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .inputNiceDates {
    grid-column: span 3;
  }

  .gridContainer2 {
    grid-column: 1/9 !important;
  }
  .defaultGrid {
    bottom: 18px;
  }

  .title {
    grid-column: 2/8;
  }

  .title2 {
    grid-column: 1/9;
  }

  .title h1,
  .title2 h1,
  .title3 h1 {
    font-size: 5.4vw !important;
    font-weight: 600;
    color: #0d214f;
  }

  .title h2,
  .title2 h2,
  .title3 h2 {
    font-size: 5vw !important;
    font-weight: 600;
  }

  .title h3,
  .title2 h3,
  .title3 h3 {
    font-size: 4.2vw !important;
  }

  .iconStyle4 {
    font-size: 4.2vw !important;
  }

  .title h4,
  .title2 h4,
  .title3 h4 {
    font-size: 3.4vw !important;
  }

  .defaultGrid form {
    display: grid;
    transition: 0.2s;
    grid-template-columns: repeat(6, 1fr);
    grid-column: 2/8;
    grid-template-rows: 90px 90px;
  }

  .inputStyleTutors {
    grid-column: 2/6 !important;
    justify-self: unset;
    width: unset;
  }
}

@media screen and (max-width: 800px) {
  .botaoPersona {
    padding: 0.3rem 0.6rem !important;
    margin: unset;

    margin-left: unset !important;
  }
}

@media screen and (max-width: 1000px) {
  .title h4,
  .title2 h4,
  .title3 h4 {
    text-align: center;
    font-size: calc(8px + 2vw);
    color: #0d214f;
  }
  .inputStyleAddTutors {
    grid-column: 1/7 !important;
    display: block;
  }
  .inputAddTutors {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .defaultGrid {
    top: 52px;
  }

  .title h1,
  .title2 h1,
  .title3 h1 {
    font-size: 4.6vw;
    color: #0d214f;
  }

  .title h2,
  .title2 h2,
  .title3 h2 {
    font-size: 3.8vw;
  }

  .title h3,
  .title2 h3,
  .title3 h3 {
    font-size: 3.4vw;
  }

  .iconStyle4 {
    font-size: 3.4vw;
  }
  .botaoPersona {
    margin-top: 10%;
  }

  .title h4,
  .title2 h4,
  .title3 h4 {
    font-size: 2.6vw;
  }
}

.estilizada {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  /* margin-bottom: 5%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada th, .estilizada td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada th {
  background-color: #f2f2f2;
  color: #0d214f;
}

.estilizada2 {
  border-collapse: collapse;
  width: 93%;
  margin-left: 3%;
  margin-top: 3%;
  /* margin-bottom: 20%; */
  /* margin: 0 auto; */
  /* margin-right: 20px; */
}

.estilizada2 th, .estilizada2 td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.estilizada2 th {
  background-color: #f2f2f2;
  color: #0d214f;
}
